import {lazy} from "react";
import {providerCheckAdmin} from "./providers/adminProvider";
import {cmsEntryPoint} from "./config";
const DarkModeSwitcher = lazy(() => import("./widgets/DarkModeSwitcher"));
const Login = lazy(() => import("./views/login/Login"));
const AuthLayout = lazy(() => import("./views/layout/AuthLayout"));
const CmsLayout = lazy(() => import("./views/layout/CmsLayout"));
const Dashboard = lazy(() => import("./views/dashboard/Dashboard"));
const Error404 = lazy(() => import("./views/error404/Error404"));
const Guard = lazy(() => import("./widgets/Guard"));
const Administrators = lazy(() => import("./views/administrators/Administrators"));
const Reset = lazy(() => import("./views/reset/Reset"));
const Email = lazy(() => import("./views/email/Email"));


const exports = {
    routes: [
        {name: "CmsLayout", path: "/cms", element: <CmsLayout />},
        {name: "AuthLayout", path: "/auth", element: <AuthLayout />},
        {name: "login", path: ["", "login"], element: <Login />, layouts: ["cms.AuthLayout"]},
        {name: "email", path: ["lozinka", "password"], element: <Email />, layouts: ["cms.AuthLayout"]},
        {name: "reset", path: ["resetuj/:token", "reset/:token"], element: <Reset />, layouts: ["cms.AuthLayout"]},
        (cmsEntryPoint === "cms.dashboard") && {
            name: "dashboard",
            path: ["", "dashboard"],
            trans: ["Dashboard", "Dashboard"],
            element: <Dashboard />,
            layouts: ["cms.CmsLayout"],
            menus: {cmsLeft: {position: 1}},
        },
        {
            name: "administrators",
            path: ["administratori", "administrators"],
            trans: ["Administratori", "Administrators"],
            element: <Administrators />,
            layouts: ["cms.CmsLayout"],
            menus: {cmsLeft: {position: 35}},
        },
        {name: "error404", path: ["*"], element: <Error404 />, layouts: ["cms.CmsLayout"]},
    ],
    widgets: {
        providerCheckAdmin: providerCheckAdmin,
        Guard: Guard,
        DarkModeSwitcher: DarkModeSwitcher
    },
}
export default exports;
