import {useQuery} from "@apollo/client";
import {GET_BLOCKS, GET_BLOCKS_QUERY} from "../graphql/blocks";
import GraphQLErrorMessage from "../../../elements/GraphQLErrorMessage";
import React, {useContext} from "react";
import Banner from "./renders/Banner";
import {module, widget} from "../../modules";
import AppContext from "../../_app/AppContext";
import BlockCategory from "./renders/BlockCategory";
import {Link} from "react-router-dom";
import LazyImage from "../../../elements/LazyImage";



function BlocksRender({code}) {
    const {route} = useContext(AppContext);
    const { data, loading, error } =  useQuery(GET_BLOCKS({admin: false}), {
        variables: {code: code, active: true}
    });

    if(loading) return <div style={{height: 1000}} />;
    if(error)   return <GraphQLErrorMessage error={error} />
    if(!data)   return null;
    const blocks = data[GET_BLOCKS_QUERY];
    if(!blocks) return null;

    const title = (item) => {
        if(!item.title && !item.subtitle)   {
            return null;
        }
        return <div className="mb-12">
            <h2 className="container mx-auto text-4xl md:text-5xl font-bold">
                <span className="under">{item.title}</span>
            </h2>
            {item.subtitle &&
            <h3 className="mt-2 whitespace-pre-wrap text-center">{item.subtitle}</h3>}
        </div>
    }

    return (
        <>
            {blocks.map((item, index) => {
                if(!item.active) return null;
                return <div key={item.id} className="mb-10 md:mb-16">

                    {item.type === 1 &&   // SLIDER
                    <div className="w-full">
                        {item.banners.length === 1
                            ? <Banner banner={item.banners[0]} />
                            : widget("design.IndexSlider", {item: item})}
                    </div>}

                    {item.type === 2 &&     // BANERI
                    <div className={item.var_3 ? "" : "container mx-auto"}>
                        {title(item)}

                        <div className="grid-cols-3 grid-cols-2 grid-cols-4 grid-cols-5 grid-cols-6" />
                        {item.var_2
                            ? widget("design.BannersCarousel", {count: item.var_1 || 2, slides: item.banners, gap: item.var_3 && 1})
                            : <div className={`md:grid grid-cols-${item.var_1 || 5} gap-3 md:gap-10`}>
                                {item.banners.map((banner, i) => {
                                    if(!banner.active) return null;
                                    return <div key={banner.id} className={`mb-3 ${(item.var_1 || 5) > item.banners.length && "md:mb-0"}`}>
                                        <Banner banner={banner}  />
                                    </div>
                                })}
                            </div>}
                    </div>}

                    {item.type === 3 && item.products && item.products.length > 0 &&  // PRODUCTS CAROUSEL
                    <div className="container mx-auto full_container">
                        {title(item)}

                        {widget("products.ProductsCarousel",
                            {products: item.products, count: item.var_1 || 5})}
                    </div>}


                    {item.type === 4 &&  // CATEGORY (PRODUCTS) CAROUSEL
                    <div className="container mx-auto">
                        <div className="border border-gray-100 border-4 pb-3">
                            {title(item)}

                            <BlockCategory block={item} />
                        </div>
                    </div>}


                    {item.type === 5 && item.categories && item.categories.length > 0 && // CATEGORIES
                    <div className="container full_container mx-auto">
                        {title(item)}

                        {widget("categories.CategoriesCarousel",
                            {blockCategories: item.categories, count: item.var_1 || 6})}
                    </div>}


                    {item.type === 6 && item.text &&  // TEXT EDITOR
                    <div className="container mx-auto" style={item.var_1 ? {maxWidth: parseInt(item.var_1)} : {}}>

                       <div className="md:grid grid-cols-3 gap-10">
                           <div className="col-span-1">
                               {title(item)}
                           </div>
                           <div className="col-span-2">
                               {module("textEditor")
                                   ? widget("textEditor.Editor", {content: item.text.text, readOnly: true})
                                   :  <div dangerouslySetInnerHTML={{__html: item.text.text}} />}
                           </div>
                       </div>
                    </div>}


                    {item.type === 7 && item.brands && item.brands.length > 0 && // BRANDS
                    <div className="container full_container mx-auto">
                        <div className="border border-gray-100 border-4 pb-3">
                            {title(item)}

                            <div>
                                {item.var_2
                                    ? widget("brands.BrandsCarousel", {brands: item.brands.map(blockBrand => blockBrand.brand), count: item.var_1 || 6})
                                    : <div className={`md:grid grid-cols-${item.var_1 || 6} gap-3 md:gap-10`}>
                                        {item.brands.map((blockBrand, i) => {
                                            const brand = blockBrand.brand;
                                            return <div key={blockBrand.id} className={`${(item.var_1 || 6) > item.brands.length && "md:mb-0"}`}>
                                                <Link to={route("products.brand", brand.name)}>
                                                    <div className="m-2">
                                                        <div className="mb-3">
                                                            <LazyImage image={brand.image} className="w-full" alt={brand.name} />
                                                        </div>
                                                        <h3 className="uppercase text-center text-lg font-semibold"> {brand.name} </h3>
                                                    </div>
                                                </Link>
                                            </div>
                                        })}
                                    </div>}
                            </div>
                        </div>
                    </div>}


                    {item.type === 8 &&  // BLOG LATEST
                    <div className="container mx-auto">
                        {title(item)}

                        {widget("blog.BlogCarousel", {count: item.var_1 || 4})}
                    </div>}
                </div>
            })}
        </>
    )
}
export default BlocksRender;