import {DateTime} from "luxon";

function SQl(ts)    {
    return DateTime.fromSQL(ts, {zone: "utc"}).toLocal();
}
export const dateTimeFormat = "dd.MM.yyyy, HH:mm:ss";
export const dateFormat = "dd.MM.yyyy";

export function fromSQL(ts, format) {
    if(format)  {
        return SQl(ts).toFormat(format);
    }
    return SQl(ts).toRelative();
}
export function time(ts) {
    const sql = SQl(ts);
    if(sql.hasSame(DateTime.local(), "day"))    {
        return sql.toFormat("HH:mm");
    }
    if(sql.hasSame(DateTime.local(), "year"))    {
        return sql.toFormat("dd. MMM HH:mm");
    }
    return sql.toFormat("dd. MMM yyyy HH:mm");
}
export function timeStarted(ts) {
    return SQl(ts) > DateTime.local();
}