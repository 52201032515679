import {gql} from "@apollo/client";


export const BLOCK_TEXT_FRAGMENT = gql`
    fragment BlockTextFragment on BlockText {
        id 
        block_id
        text
        texts
    }
`;

export const UPSERT_BLOCK_TEXT = gql`
    ${BLOCK_TEXT_FRAGMENT} 
    mutation ($id: ID, $block_id: ID, $text: String)  {
        upsertBlockText(id: $id, block_id: $block_id, text: $text) {
            ...BlockTextFragment
        }
    }
`