import {gql} from "@apollo/client";
import {BLOCK_BANNER_FRAGMENT} from "./blockBanner";
import {BLOCK_TEXT_FRAGMENT} from "./blockText";
import {BLOCK_CATEGORY_FRAGMENT} from "./blockCategory";
import {BLOCK_BRAND_FRAGMENT} from "./blockBrand";
import {graphql, module} from "../../modules";
import {types} from "../config";


export const BLOCK_FRAGMENT = gql`
    fragment BlockFragment on Block {
        id
        active
        type
        code
        special
        position
        title
        titles
        subtitle
        subtitles
        var_1
        var_2
        var_3
        block_start
        block_end
    }
`;

export const GET_BLOCKS_QUERY = "getBlocks";
export function GET_BLOCKS({admin=false})    {
    return gql`
        ${BLOCK_FRAGMENT}
        ${BLOCK_BANNER_FRAGMENT}
        ${module("categories") && types[5] ? BLOCK_CATEGORY_FRAGMENT : ""}
        ${types[6] ? BLOCK_TEXT_FRAGMENT : ""}
        ${graphql("storage.storage").fragment}
        ${graphql("products.product").fragment}
        ${module("brands") && types[7] ? BLOCK_BRAND_FRAGMENT : ""}
        ${module("brands") && types[7] ? graphql("brands.brand").fragment : ""}
        query ($code: String!, $active: Boolean) {
            getBlocks(code: $code, active: $active) {
                ...BlockFragment
                banners {
                    ...BlockBannerFragment
                    ${graphql("storage.storage", "image").body}
                    ${graphql("storage.storage", "mobile_image").body}
                    ${admin ? graphql("storage.storage", "images").body : ""}
                    ${admin ? graphql("storage.storage", "mobile_images").body : ""}
                }
                ${types[6] ? `text {
                    ...BlockTextFragment
                }` : ""}
                ${module("categories") && types[5] 
                 ?  `categories {
                    ...BlockCategoryFragment
                }` : ""}
                ${graphql("products.product", "products").body}
                ${module("brands") && types[7] 
                 ?  `brands {
                    ...BlockBrandFragment
                    ${graphql("brands.brand", "brand").body}
                }` : ""}
            }
        }
    `
}

export const UPSERT_BLOCK = gql`
    ${BLOCK_FRAGMENT}
    mutation ($id: ID, $active: Boolean, $code: String, $type: Int, $title: String, $subtitle: String,
        $var_1: String, $var_2: String, $var_3: String, $block_start: String, $block_end: String,
        $detach_product: ID, $attach_products: [ID], $order_products: [ID])  {
        upsertBlock(id: $id, active: $active, code: $code, type: $type, title: $title, subtitle: $subtitle,
         var_1: $var_1, var_2: $var_2, var_3: $var_3, block_start: $block_start, block_end: $block_end,
        detach_product: $detach_product, attach_products: $attach_products, order_products: $order_products) {
            ...BlockFragment
        }
    }
`

export const DELETE_BLOCK = gql`
    ${BLOCK_FRAGMENT}
    mutation ($id: ID!) {
        deleteBlock(id: $id) {
            ...BlockFragment
        }
    }
`