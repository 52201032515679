import {gql} from "@apollo/client";


export const BLOCK_BRAND_FRAGMENT = gql`
    fragment BlockBrandFragment on BlockBrand {
        id
        block_id
        brand_id
        position
    }
`;

export const UPSERT_BLOCK_BRAND = gql`
    ${BLOCK_BRAND_FRAGMENT}
    mutation ($id: ID, $block_id: ID, $brand_id: ID)  {
        upsertBlockBrand(id: $id, block_id: $block_id, brand_id: $brand_id) {
            ...BlockBrandFragment
        }
    }
`
export const DELETE_BLOCK_BRAND = gql`
    ${BLOCK_BRAND_FRAGMENT}
    mutation ($id: ID!) {
        deleteBlockBrand(id: $id) {
            ...BlockBrandFragment
        }
    }
`