import {useQuery} from "@apollo/client";
import {GET_SETTINGS_QUERY, GET_SETTINGS} from "../graphql/setting";


function usePreloadSettings(context) {
    const {data, loading} = useQuery(GET_SETTINGS());
    if(loading) {
        context.wait.push("settings");
        return null;
    }
    let settings = null;
    if(data && data[GET_SETTINGS_QUERY])    {
        settings = {};
        for(let i=0; i<data[GET_SETTINGS_QUERY].length; i++)    {
            const item = data[GET_SETTINGS_QUERY][i];
            settings[item.field] = item;
        }
    }

    const setting = (name, full=false) => {
        if(!settings) return "";
        if(!name) return "";
        if(full)    {
            return settings[name] || {};
        }
        if(!settings[name]?.values[context.lang]) {
            return settings[name]?.values[0];
        }
        return settings[name]?.values[context.lang] || "";
    }

    return {settings, setting}
}
export default usePreloadSettings;