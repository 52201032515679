import {useQuery} from "@apollo/client";
import {GET_CATEGORIES, GET_CATEGORIES_QUERY} from "../graphql/categories";
import {useMemo} from "react";


function usePreloadCategories(context) {
    const {data, previousData} = useQuery(GET_CATEGORIES(), {
        notifyOnNetworkStatusChange: true,
    });

    let categoriesData = [];
    if(previousData && previousData[GET_CATEGORIES_QUERY]) categoriesData = previousData[GET_CATEGORIES_QUERY];
    if(data && data[GET_CATEGORIES_QUERY]) categoriesData = data[GET_CATEGORIES_QUERY];

    const looper = (arr) => {
        const categories = [];
        const search = {};
        for(let i=0; i<arr.length; i ++) {
            const item = {...arr[i], children: [], breadcrumbs: [arr[i]], orig_count: arr[i].products_count, ids: [arr[i].id]};
            search[item.id] = item;
            categories.push(item);
        }
        for(let i=0; i<categories.length; i ++) {
            const item = categories[i];
            if(item.parent_id)  {
                const parent1 = search[item.parent_id];
                if(!parent1) continue;
                item.breadcrumbs.unshift(parent1);
                parent1.children.push(item);
                parent1.ids.push(item.id);
                parent1.products_count = parent1.products_count + item.orig_count;
                if(parent1.parent_id)   {
                    const parent2 = search[parent1.parent_id];
                    if(!parent2) continue;
                    item.breadcrumbs.unshift(parent2);
                    parent2.ids.push(item.id);
                    parent2.products_count = parent2.products_count + item.orig_count;
                }
            }
        }
        return [categories, search];
    }
    const [categories, search] = useMemo(() => looper(categoriesData), [categoriesData]);

    const categoryById = (categoryId) => {
        if(!categoryId) return null;
        if(!search[categoryId]) return null;
        return search[categoryId];
    }
    const categoryBySlug = (categorySlug) => {
        if(!categorySlug) return null;
        return categories.find(item => item.slug === categorySlug);
    }
    return {categories, looper, categoryById, categoryBySlug}
}
export default usePreloadCategories;