import React, {useEffect, useRef, useState} from "react";
import {widget} from "../modules/modules";


function LazyImage({image, src, ration, alt, className, big=false}) {
    const [visible, setVisible] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const imageRef = useRef(null);

    useEffect(() => {
        if (!('IntersectionObserver' in window)) {
            setVisible(true);
            return
        }
        let scopeVisible = false;
        const observer = new IntersectionObserver(
            ([e]) => {
                if(!scopeVisible && e.intersectionRatio > 0) {
                    setVisible(true);
                    scopeVisible = true;
                }
            },
            {root: null, threshold: .1}
        );
        observer.observe(imageRef.current);
    }, []);

    let aspect = "37%";
    let imgSrc = "";
    if(image)   {
        if(image?.width && image?.height)   {
            aspect = ((image?.height*100) / image?.width) + "%";
        }
        imgSrc = widget("storage.image")(image, big);
    } else if(src)  {
        if(ration) {
            aspect = ration + "%";
        }
        imgSrc = src;
    } else {
        imgSrc = widget("storage.image")(image);
    }


   return <div ref={imageRef} className={loaded ? "" : `${className ? className : ""} bg-gray-300 opacity-10 animate-pulse relative`}
                       style={loaded ? {} : {paddingTop: aspect}}>
        {visible &&
        <img
            className={`${className ? className : ""} ${loaded ? "" : "absolute top-0"} max-w-full`}
            src={imgSrc}
            onLoad={() => setLoaded(true)}
            alt={alt || ""}  />}
    </div>
}
export default LazyImage;