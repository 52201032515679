
export const productGraphql = {
    price: true,
    qty: false,
    group: true,
    specifications: true,
    connected: true,
    youtube: false,
}
export const shopUrls = ["prodavnica", "shop"]
//export const shopUrls = ["usluge", "services"]
export const productUrls = ["proizvod", "product"]
//export const productUrls = ["portfolio", "portfolios"]