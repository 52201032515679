import {gql} from "@apollo/client";
import {graphql} from "../../modules";
import {loadImages} from "../config";


const SETTING_FRAGMENT = gql`
    fragment SettingFragment on Setting {
        id
        field
        value
    }
`;

export const GET_SETTINGS_QUERY = "getSettings";
export function GET_SETTINGS()    {
    return gql`
        ${SETTING_FRAGMENT}
        ${loadImages ? graphql("storage.storage").fragment : ""}
        query {
            getSettings {
                ...SettingFragment
                ${loadImages ? graphql("storage.storage", "image").body : ""}
                values
            }
        }
    `
}

export const UPSERT_SETTING = gql`
    ${SETTING_FRAGMENT}
    mutation UpsertSetting($id: ID, $field: String, $value: String, $image_id: ID)  {
        upsertSetting(id: $id, field: $field, value: $value, image_id: $image_id) {
            ...SettingFragment
        }
    }
`

