import {seoAll} from "./providers/seoProvider";
import RouteSeoWrapper from "./wrappers/RouteSeoWrapper";


const exports = {
    routes: [

    ],
    wrappers: [RouteSeoWrapper],
    widgets: {
        seoAll: seoAll
    }
}
export default exports;