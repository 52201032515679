import {randString} from "../../../elements/helpers";

function useSetClientCode(context)  {
    let code = localStorage.getItem("client");
    if(!code)   {
        const abcd = ['A', 'D', 'f', 'T', 'q', 'M', 'c', 'S', 'o', 'P'];
        const unique = Date.now().toString();
        code = randString(4);
        for (var i = 0; i < unique.length; i++) {
            code = code + abcd[unique.charAt(i)];
        }
        localStorage.setItem("client", code);
    }
    return {client: code}
}
export default useSetClientCode;