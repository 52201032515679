
export const types = {
    1: {type: 1, name: ["veliki slajder", "big slider"]},
    2: {type: 2, name: ["baneri", "banners"]},
    3: {type: 3, name: ["carousel proizvoda", "products carousel"]},
    //4: {type: 4, name: ["carousel proizvoda (kategorija)", "products carousel (category)"]},
    5: {type: 5, name: ["kategorije u carousel-u", "categories carousel"]},
    6: {type: 6, name: ["TEXT editor", "TEXT editor"]},
    //7: {type: 7, name: ["brendovi u carousel-u", "brands carousel"]},
    8: {type: 8, name: ["BLOG carousel", "BLOG carousel"]},
};
