import {useEffect} from "react";

function usePreloadReadOnly(context) {

    useEffect(() => {
        import('../widgets/ReadOnlyEditor').then(mod => {
            // my-module is ready
        });
    }, [])
    return null
}
export default usePreloadReadOnly;