import {widget} from "../../../modules";
import {useContext} from "react";
import AppContext from "../../../_app/AppContext";


const BlockCategory = ({block}) => {
    const {categoryById} = useContext(AppContext);
    const category = block.categories.length > 0 ? block.categories[block.categories.length-1] : null;
    const stored = categoryById(category.category_id);

    return (
        <>
            {widget("products.LoadProductsCarousel",
                {variables: { // TODO multiple ids za kategoriju
                        category: stored?.ids || undefined,
                        first: category?.max || undefined,
                        price: category?.price ? parseFloat(category?.price) : undefined,
                    },
                    count: block.var_1 || 5,
                })}
        </>

    );
};

export default BlockCategory;