import {lazy} from "react";
import usePreloadSettings from "./preloads/usePreloadSettings";
import {SETTING_FRAGMENT} from "./graphql/setting";
const Settings = lazy(() => import("./views/settings/Settings"));
const UpdateField = lazy(() => import("./widgets/UpdateField"));


const exports = {
    routes: [
        {
            name: "settings",
            path: ["podesavanja", "settings"],
            trans: ["Podešavanja", "Settings"],
            element: <Settings />,
            layouts: ["cms.CmsLayout"],
            menus: {cmsLeft: {position: 30}},
        },
    ],
    preloads: [usePreloadSettings],
    widgets: {
        UpdateField: UpdateField
    }
}
export default exports;