import {gql} from "@apollo/client";


export const BLOCK_CATEGORY_FRAGMENT = gql`
    fragment BlockCategoryFragment on BlockCategory {
        id
        block_id
        category_id
        position
        price
        max
    }
`;

export const UPSERT_BLOCK_CATEGORY = gql`
    ${BLOCK_CATEGORY_FRAGMENT}
    mutation ($id: ID, $block_id: ID, $category_id: ID, $price: Float, $max: Int)  {
        upsertBlockCategory(id: $id, block_id: $block_id, category_id: $category_id, price: $price, max: $max) {
            ...BlockCategoryFragment
        }
    }
`
export const DELETE_BLOCK_CATEGORY = gql`
    ${BLOCK_CATEGORY_FRAGMENT}
    mutation ($id: ID!) {
        deleteBlockCategory(id: $id) {
            ...BlockCategoryFragment
        }
    }
`