import React, {useCallback, useContext, useEffect, useState} from "react";
import useEmblaCarousel from "embla-carousel-react";
import {Link} from "react-router-dom";
import AppContext from "../../_app/AppContext";
import {DotButton, NextButton, PrevButton} from "../../../elements/EmblaNavigation";
import {useQuery} from "@apollo/client";
import {GET_BLOGS, GET_BLOGS_QUERY} from "../graphql/blog";
import Loader from "../../../elements/Loader";
import {ArrowRight, Calendar} from "react-feather";
import {time} from "../../../elements/time";

const Carousel = ({blog, count}) => {
    const {route, trans} = useContext(AppContext);
    const [viewportRef, embla] = useEmblaCarousel({
        containScroll: "trimSnaps",
        align: "start",
        loop: true
    });

    const [selectedIndex, setSelectedIndex] = useState(0);
    const [scrollSnaps, setScrollSnaps] = useState([]);
    const scrollTo = useCallback((index) => embla && embla.scrollTo(index), [
        embla
    ]);

    const [prevBtnEnabled, setPrevBtnEnabled] = useState(false);
    const [nextBtnEnabled, setNextBtnEnabled] = useState(false);
    const scrollPrev = useCallback(() => embla && embla.scrollPrev(), [embla]);
    const scrollNext = useCallback(() => embla && embla.scrollNext(), [embla]);

    const onSelect = useCallback(() => {
        if (!embla) return;
        setSelectedIndex(embla.selectedScrollSnap());
        setPrevBtnEnabled(embla.canScrollPrev());
        setNextBtnEnabled(embla.canScrollNext());
    }, [embla, setSelectedIndex]);

    useEffect(() => {
        if (!embla) return;
        onSelect();
        setScrollSnaps(embla.scrollSnapList());
        embla.on("select", onSelect);
    }, [embla, setScrollSnaps, onSelect]);

    return (
        <div className="embla">
            <div className="embla__viewport" ref={viewportRef}>
                <div className="embla__container gap-3">
                    {blog.map(item => {
                        return <article style={{minWidth: 100/count + "%", width: 100/count + "%"}}
                                    className="embla__slide embla_slide_mobile pl-3" key={item.id}>

                            <div className="text-gray-500 mb-3">
                                <Calendar /> {time(item.created_at)}
                            </div>

                            <div className="mb-5">
                                <Link to={route("blog.blog", item)}>
                                    <h2 className="text-lg md:text-xl font-semibold">{item.title}</h2>
                                </Link>
                            </div>

                            <div>
                                <Link to={route("blog.blog", item)} className="link">
                                    {trans("detaljnije")} <ArrowRight strokeWidth={3} size={14} />
                                </Link>
                            </div>
                        </article>
                    })}
                </div>
            </div>

            <div className="hidden">
                <PrevButton onClick={scrollPrev} enabled={prevBtnEnabled} />
                <NextButton onClick={scrollNext} enabled={nextBtnEnabled} />
            </div>

            <div className="embla__dots relative mt-8 d:hidden">
                {scrollSnaps.map((_, index) => (
                    <DotButton
                        key={index}
                        selected={index === selectedIndex}
                        onClick={() => scrollTo(index)}
                    />
                ))}
            </div>
        </div>
    );
};

const BlogCarousel = ({count}) => {
    const {data, loading} = useQuery(GET_BLOGS, {
        variables: {first: 10}
    });
    if(loading) return <Loader />;
    const blog = data[GET_BLOGS_QUERY];
    return <Carousel blog={blog.data} count={count} />
};

export default BlogCarousel;