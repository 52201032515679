import {gql} from "@apollo/client";
import {PAGINATOR_INFO_FRAGMENT} from "../../../elements/graphql/paginatorInfo";
import {PRODUCT_SPECIFICATION_FRAGMENT} from "./specification";
import {graphql, module} from "../../modules";
import {productGraphql} from "../config";


export default function PRODUCT_FRAGMENT() {
    return gql`
        ${graphql("storage.storage").fragment}
        ${graphql("categories.category").fragment}
        fragment ProductFragment on Product {
            id
            active
            group_id
            connected_id
            name
            names
            code
            barcode
            brand
            price
            prices
            discount_price
            discount_prices
            process_price
            action
            new
            qty
            badge_1
            badge_2
            description
            descriptions
            seo_title
            seo_keywords
            seo_description
            video
            seo_titles
            seo_keywords_all
            seo_descriptions
            discount_price_start
            discount_price_end
            ${graphql("categories.category", "categories").body}
            ${graphql("storage.storage", "image").body}
        }
    `;
}

export function CREATE_PRODUCT() {
    return gql`
        ${PRODUCT_FRAGMENT()}
        mutation ($name: String!)  {
            createProduct(name: $name) {
                ...ProductFragment
            }
        }
    `
}

export const GET_PRODUCT_QUERY = "getProduct";
export function GET_PRODUCT() {
    return gql`
        ${PRODUCT_FRAGMENT()}
        ${productGraphql.specifications ? PRODUCT_SPECIFICATION_FRAGMENT : ""}
        ${graphql("filters.filter").fragment}
        query($id: ID!) {
            getProduct(id: $id) {
                ...ProductFragment
                ${productGraphql.specifications ? `specifications {
                ...ProductSpecificationFragment
            }` : ""}
                ${productGraphql.connected ? `connected {
                ...ProductFragment
            }` : ""}
                ${productGraphql.group ? `group {
                ...ProductFragment
            }` : ""}
                ${graphql("storage.storage", "images").body}
                ${graphql("filters.filter", "filters").body}
            }
        }
    `
}


export const GET_PRODUCTS_QUERY = "getProducts";
export function GET_PRODUCTS({views, suppliers} = {}) {
    return gql`
        ${PRODUCT_FRAGMENT()}
        ${suppliers ? graphql("suppliers.supplier").fragment : ""}
        ${module("suppliers") ? graphql("suppliers.supplierProduct").fragment : ""}
        ${PAGINATOR_INFO_FRAGMENT}
        query($first: Int, $page: Int, $search: String, $order: Int, $ids: [ID!],
            $category: [ID], $filters: String, $brands: [String], $price: Float, $brandName: String, $pageName: String,
            $start_date: String, $end_date: String, $hasViews: Int, $supplier: [ID!]) {
            getProducts(first: $first, page: $page, search: $search, order: $order, ids: $ids, brands: $brands,
                category: $category, filters: $filters, price: $price, brandName: $brandName, pageName: $pageName,
                start_date: $start_date, end_date: $end_date, hasViews: $hasViews, supplier: $supplier) {
                data {
                    ...ProductFragment
                    ${views ? "views_count" : ""}
                    ${productGraphql.group ? `group {
                        ...ProductFragment
                    }` : ""}
                    ${suppliers ? graphql("suppliers.supplierProduct", "supplier_products", "supplier { ...SupplierFragment }").body : ""}
                    ${module("suppliers") ? graphql("suppliers.supplierProduct", "supplier_product").body : ""}
                }
                paginatorInfo {
                    ...PaginatorInfoFragment
                }
            }
        }
    `
}

export function UPDATE_PRODUCT() {
    return gql`
        ${PRODUCT_FRAGMENT()}
        mutation ($id: ID!, $position: Int, $name: String, $code: String, $barcode: String, $brand: String, $image_id: ID, $active: Boolean,
            $group_id: String, $connected_id: String, $price: Float, $discount_price: Float, $action: Boolean, $new: Boolean, $video: String,
            $description: String, $seo_title: String, $seo_description: String, $seo_keywords: String,
            $discount_price_start: String, $discount_price_end: String,
            $detach_image: ID, $attach_images: [ID], $order_images: [ID],
            $attach_categories: [ID], $detach_categories: [ID], $attach_filters: [ID], $detach_filters: [ID],
            $attach_specification: Boolean, $detach_specification: ID, 
            $badge_1: Boolean, $badge_2: Boolean)  {

            updateProduct(id: $id, position: $position, name: $name, code: $code, brand: $brand, image_id: $image_id,
                active: $active, group_id: $group_id, connected_id: $connected_id, price: $price, discount_price: $discount_price,
                action: $action, new: $new, description: $description, video: $video, barcode: $barcode,
                seo_title: $seo_title, seo_description: $seo_description, seo_keywords: $seo_keywords,
                discount_price_start: $discount_price_start, discount_price_end: $discount_price_end,
                detach_image: $detach_image, attach_images: $attach_images, order_images: $order_images,
                attach_categories: $attach_categories, detach_categories: $detach_categories,
                attach_filters: $attach_filters, detach_filters: $detach_filters,
                attach_specification: $attach_specification, detach_specification: $detach_specification,
                badge_1: $badge_1, badge_2: $badge_2) {
                ...ProductFragment
            }
        }
    `
}

export function DELETE_PRODUCT() {
    return gql`
        ${PRODUCT_FRAGMENT()}
        mutation ($id: ID!) {
            deleteProduct(id: $id) {
                ...ProductFragment
            }
        }
    `
}