import {useContext} from "react";
import AppContext from "../../_app/AppContext";

function useGetMenu(menu)    {
    const {lang, pages, route, categoryById} = useContext(AppContext);

    const holder = [];
    for(let i=0; i<pages.length; i++) {
        const item = pages[i];
        if(item.menu !== menu) continue;
        if(!item.active) continue;

        let path = "";
        let category = null;
        if(item.page)    {
            path = route(item.page);
        } else if(item.type === 1) {
            path = "/" + item.slugs[lang];
        } else if(item.type === 2 && item.category_id && categoryById)  {
            category = categoryById(item.category_id);
            path = route("products.category", category);
        } else if(item.type === 3 && item.brand_name)  {
            path = route("products.brand", item.brand_name);
        }
        holder.push({...item, path: path, category: category, name: item.names[lang]});
    }
    return holder;
}
export default useGetMenu;
