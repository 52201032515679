import React, {useContext} from "react";
import Ripples from "react-ripples";
import {ShoppingBag} from "react-feather";
import AppContext from "../../_app/AppContext";


function BasketButton({onClick}) {
    const {trans, basket} = useContext(AppContext);

    return (
        <Ripples>
            <button onClick={onClick} className="w-full md:p-3">
                <div className="relative inline-block">
                    <span  style={{top: -8, right: -12}} className="absolute pointer-events-none text-sm z-10 bg-pubprimary-1 rounded-full font-bold w-5 h-5 text-center">
                      {basket?.length > 0 &&
                      <span className="inline-block bg-pubprimary-1 rounded-full w-full h-full absolute top-0 left-0"/>}
                        <div className="relative z-10 text-md"> {basket?.length} </div>
                    </span>
                    <ShoppingBag />
                </div>
                {<div className="text-xs md:hidden">{trans("korpa")}</div>}
            </button>
        </Ripples>
    )
}

export default BasketButton;