import {useContext} from "react";
import AppContext from "../../../_app/AppContext";
import {widget} from "../../../modules";


function PageTemplate() {
    const {currentRoute, setting} = useContext(AppContext);
    if(currentRoute.page?.block_code)  {
        const welcomePage = currentRoute.fullpath.includes("/");
        return <>
            {!welcomePage && widget("public.Breadcrumbs", {links: [{name: currentRoute.page?.name}]})}
            <div className={`${welcomePage ? "mb-12 md:mb-20": "my-12 md:my-20"}`}>

                {widget("design.BlocksRender", {code: currentRoute.page?.block_code, welcomePage})}
            </div>

        </>
    }

    return null
}
export default PageTemplate;