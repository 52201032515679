import {Link} from "react-router-dom";
import React from "react";
import LazyImage from "../../../../elements/LazyImage";


function Banner({banner, className}) {
    if(!banner.active) return null;

    let width;
    if(window.outerHeight)   width = window.outerWidth;
    else                     width = document.body.clientWidth;

    const image = <LazyImage
        image={width > 768 ? banner.image : banner.mobile_image}
        alt={banner.alt}
        className={className}
        big={width > 768} />

    if(banner.link)     {
        if(banner.blank)        {
            return <a href={banner.link} target="_blank" rel="noreferrer">
                <div className="h-full">
                {image}
                </div>
            </a>
        }
        return <Link to={banner.link}>
            <div className="h-full">
                {image}
            </div>
        </Link>
    }
    return image;
}
export default Banner;