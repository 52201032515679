import {lazy} from "react";
import usePreloadReadOnly from "./preloads/usePreloadReadOnly";
const RichTextEditor = lazy(() => import("./widgets/Editor/RichTextEditor"));


const exports = {
    preloads: [usePreloadReadOnly],
    widgets: {
        Editor: RichTextEditor
    }
}
export default exports;