import {ApolloClient, from, InMemoryCache} from "@apollo/client";
import {setContext} from "@apollo/client/link/context";
import {createUploadLink} from "apollo-upload-client";
import {graphQL} from "./config";
import {widget} from "../modules";



export function uploadLink() {
    function customFetch(url, opts = {}) {  // LOAD PERCENTAGE CALCULATE (FOR STORAGE)
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest()

            xhr.open(opts.method || 'get', url)

            for (let k in opts.headers || {}) xhr.setRequestHeader(k, opts.headers[k])

            xhr.onload = e =>
                resolve({
                    ok: true,
                    text: () => Promise.resolve(e.target.responseText),
                    json: () => Promise.resolve(JSON.parse(e.target.responseText))
                })

            xhr.onerror = reject

            if (xhr.upload)
                xhr.upload.onprogress = opts.onProgress

            xhr.send(opts.body)
        });
    }
    /*const httpLink = createHttpLink({ // ne treba kad je uploadLink (samo jedan)
        uri: graphQL(),
    });*/
    return createUploadLink({
        uri: graphQL(),
        fetch: typeof window === 'undefined' ? global.fetch : customFetch
    });
}
export function authLink(lang) {
    //const userToken = providerCheckUser();

    return  setContext((_, { headers }) => {
        return {
            headers: {
                ...headers,
                Authorization: `Bearer ${widget("cms.providerCheckAdmin", null)}`,
                Authuser: widget("users.providerCheckUser", null),
                Lang: lang || 0
            }
        }
    });
}

export function apolloClient(lang) {
    return  new ApolloClient({
        cache: new InMemoryCache({
            typePolicies: {
                Setting: {
                    //keyFields: ["field"]
                },
                Product: {
                    fields: {
                        /*name: {merge(existing, incoming)
                            { return  incoming || existing },},
                        slug: {merge(e, i) { return  i ? i : e },},
                        image: {merge(e, i) { return  i ? i : e },},*/
                    },
                    //keyFields: false
                },
            }
        }),
        link: from([authLink(lang), uploadLink()]),
    });
}
