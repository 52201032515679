import {useQuery} from "@apollo/client";
import {GET_PAGES, GET_PAGES_QUERY} from "../graphql/pages";
import PageTemplate from "../views/templates/PageTemplate";
import {addToRoutes, modulesRoutes} from "../../modules";



function useAddPagesToRoutes(context) {
    const {data, loading} = useQuery(GET_PAGES);
    if(loading) {
        context.wait.push("pages");
        return null;
    }
    const pages = (data && data[GET_PAGES_QUERY]) ? data[GET_PAGES_QUERY] : [];
    const holder = {};

    for(let i=0; i<pages.length; i++)   {
        const page = pages[i];
        if(page.page && modulesRoutes[page.page])   {
            modulesRoutes[page.page].page = page;
            continue;
        }
        holder[`page.${page.id}`] = {
            path: page.slugs,
            element: <PageTemplate />,
            layouts: ["public.PublicLayout"],
            page: page,
            name: `page.${page.id}`}
    }
    addToRoutes(holder);
    return {pages}
}
export default useAddPagesToRoutes;