import {lazy} from "react";
import usePreloadBasket from "./preloads/usePreloadBasket";
import BasketButton from "./widgets/BasketButton";
const CmsCalendar = lazy(() => import("./views/cmsCalendar/CmsCalendar"));
const CmsPromoCodes = lazy(() => import("./views/cmsPromoCodes/CmsPromoCodes"));
const CmsOrdersDrawer = lazy(() => import("./widgets/CmsOrdersDrawer"));
const CmsOrderDrawer = lazy(() => import("./widgets/CmsOrderDrawer"));
const CmsOrders = lazy(() => import("./views/cmsOrders/CmsOrders"));
const UserOrders = lazy(() => import("./views/userOrders/UserOrders"));
const CmsOrdersUnseen = lazy(() => import("./views/cmsOrders/CmsOrdersUnseen"));
const Checkout = lazy(() => import("./views/checkout/Checkout"));
const OrderStatus = lazy(() => import("./views/status/OrderStatus"));
const ProductForm = lazy(() => import("./widgets/ProductForm"));
const BasketDrawerContent = lazy(() => import("./widgets/BasketDrawerContent"));


const exports = {
    routes: [
        {
            name: "cmsOrdersTab",
            trans: ["Porudžbine", "Orders"],
            path: ["porudzbine", "orders"],
            element: <CmsOrders />,
            append: <CmsOrdersUnseen />,
            layouts: ["cms.CmsLayout"],
            menus: {cmsLeft: {position: 30}},
        },
        {
            name: "cmsCalendar",
            trans: ["Kalendar", "Calendar"],
            path: ["porudzbine-kalendar", "orders-calendar"],
            parent: "orders.cmsOrdersTab",
            element: <CmsCalendar />,
            layouts: ["cms.CmsLayout"],
            menus: {cmsLeft: {position: 1}},
        },
        {
            name: "cmsPromoCodes",
            trans: ["Promo kodovi", "Promo codes"],
            path: ["promo-kodovi", "promo-codes"],
            parent: "orders.cmsOrdersTab",
            element: <CmsPromoCodes />,
            layouts: ["cms.CmsLayout"],
            menus: {cmsLeft: {position: 2}},
        },
        {
            name: "checkout",
            trans: ["Korpa", "Checkout"],
            path: ["placanje", "checkout"],
            element: <Checkout />,
            layouts: ["public.PublicLayout"],
        },
        {
            name: "status",
            path: ["porudzbina/:code", "order/:code"],
            element: <OrderStatus />,
            compile: (code, lang) => {
                return [`/porudzbina/${code}`, `/order/${code}`][lang];
            },
            layouts: ["public.PublicLayout"],
        },
        {
            name: "userOrders",
            path: ["porudzbine", "orders"],
            element: <UserOrders />,
            layouts: ["public.PublicLayout", "users.UserLayout"],
        },
        {name: "status1", path: ["porudzbina/:code/:thankYou", "order/:code/:thankYou"], element: <OrderStatus />, layouts: ["public.PublicLayout"]}
    ],
    preloads: [usePreloadBasket],
    widgets:  {
        ProductForm: ProductForm,
        BasketButton: BasketButton,
        BasketDrawerContent: BasketDrawerContent,
        CmsOrdersDrawer: CmsOrdersDrawer,
        CmsOrderDrawer: CmsOrderDrawer,
    }
}
export default exports;